import { Container, Row, Col } from "react-bootstrap";
import lambdablg from '../assets/img/serverless-lambda.png';
import jsReactBlg from '../assets/img/js - react.png';

export const Blog = () => {
    return (
        <section className="py-3 mb-5 blogs" id="blogs">
            <h2>Blogs</h2>
            <Container className="pt-4">
                <Row>
                    <Col sm={12} md={6} lg={4} className="mb-2">
                        <div className="card p-3">
                            <img src={lambdablg} className="card-image" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Building a Serverless Contact Form using AWS Lambda, React, API Gateway, and SNS</h5>
                                {/* <p className="card-text">In this blog, we’ll walk through a straightforward way to add a contact form to your portfolio website using React for the front end and AWS Lambda for the back end.</p> */}
                                <a href="https://medium.com/@oceansindhu200/building-a-serverless-contact-form-using-aws-lambda-react-api-gateway-and-sns-a49bd3cf87f6"
                                    target="_blank">Read More...</a>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="mb-2">
                        <div className="card p-3">
                            <img src={jsReactBlg} className="card-image" alt="..." style={{ objectFit: 'contain' }} />
                            <div className="card-body">
                                <h5 className="card-title">Navigating the Mindset Shift: Thinking in Vanilla JavaScript vs. Thinking in React</h5>
                                {/* <p className="card-text">The advent of React has revolutionized the way we build user interfaces, introducing a component-based architecture that has simplified complex UI development.However, understanding the transition from thinking in vanilla JavaScript to thinking in React can be</p> */}
                                <a href="https://medium.com/@oceansindhu200/navigating-the-mindset-shift-thinking-in-vanilla-javascript-vs-thinking-in-react-79e522f3d002"
                                    target="_blank">Read More...</a>
                            </div>
                        </div>
                    </Col>
                </Row>


            </Container>
        </section>
    )
}