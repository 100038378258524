export const projects = [
    {
        id: 1,
        title: "MyanChaw",
        description: "An online platform empowering local delivery companies to connect with new customers and thrive in the digital age.",
        image: {
            path: "myanchaw.png",
            isContain: false
        },
        tags: [
            "Spring Boot",
            "Thymeleaf",
            "JavaScript",
            "TypeScript",
            "React Js",
            "Bootstrap",
            "PostgreSQL",
            "REST API"
        ],
        github: "",
        webapp: "https://www.myanchaw.com/"
    },
    {
        id: 2,
        title: "MMBusTicket",
        description: "A user-friendly online platform for the bus ticketing bookings throughout Myanmar. ",
        image: {
            path: "mmbusTicket.png",
            isContain: false
        },
        tags: [
            "Java",
            "Play Framework",
            "PostgreSQL",
            "JavaScript",
            "HTMX",
            "Bootstrap",
            "REST API",
            "JUnit",
            "Mockito"
        ],
        github: "",
        webapp: "http://www.mmbusticket.com/"
    },
    {
        id: 3,
        title: "Employee Management",
        description: "A Web application for employee managementp, utilizing separate Maven modules for backend, frontend, and security components",
        image: {
            path: "employee-mng.png",
            isContain: false
        },
        tags: [
            "Java",
            "J2EE",
            "JavaScript",
            "PostgreSQL",
            "JSP",
            "Bootstrap",
            "JUnit",
            "Mockito",
            "Docker",
            "Jenkins",
            "SonarQube",
            "REST API"
        ],
        github: "https://github.com/Kumaridu/EmployeeCore",
        webapp: ""
    },
    {
        id: 4,
        title: "Finco",
        description: "A reusable Java framework for common financial functionalities",
        image: {
            path: "asd.png",
            isContain: false
        },
        tags: [
            "Java",
            "Utilize Design Patterns"

        ],
        github: "https://github.com/Kumaridu/EmployeeCore",
        webapp: ""
    },
    {
        id: 5,
        title: "MMQuickShop",
        // description: `An Android support application tailored for our online store web platform. 
        //  This app enables users to easily access and manage order information, providing a convenient order list with efficient search functionality and notifying them whenever an order is confirmed.`,
        description: `An Android support app for the online strore web platform to easily access and manage order information.`,
        image: {
            path: "mmquickshop-mobile.png",
            isContain: true
        },
        tags: [
            "Java",
            "Android Studio",
            "Firebase",
            "Retrofit",
            "REST APIs"

        ],
        github: "https://github.com/Kumaridu/mmquickshop-mobile",
        webapp: ""
    },
    {
        id: 6,
        title: "Programming Paradigm",
        description: `A web application developed using Spring Boot, featuring CRUD operations and an administrative portal.`,
        image: {
            path: "programming-paradigm.png",
            isContain: false
        },
        tags: [
            "Java",
            "Spring Boot",
            "Thymeleaf",
            "Tailwind",
            "REST APIs"
        ],
        github: "https://github.com/Kumaridu/programming-paradigm",
        webapp: ""
    }
]