import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { Container, Row, Col } from "react-bootstrap";
import uit from '../assets/img/uit.jpg';
import miu from '../assets/img/miu.jpg';

export default function Summary() {
    return (
        <section className="education py-3 mb-5" id="experience">
            <Container className="pt-3">
                <h2>Education & Experience</h2>
                <Row>
                    <Col sm={12} md={6}>
                        <h5 className="pt-3">Education</h5>
                        <MDBContainer className="pt-2">
                            <ul className="timeline">
                                <li className="timeline-item mb-5">
                                    <div className="d-flex">
                                        {/* <img src={miu} style={{ width: '35px', height: '35px' }} /> */}
                                        <div>
                                            <h5 className="fw-bold">Maharishi International University</h5>
                                            <p className="mb-2 fw-bold">Master of Science in Computer Science</p>
                                            <p>Jan 2023 - Oct 2025</p>
                                        </div>
                                    </div>
                                </li>

                                <li className="timeline-item mb-5">
                                    <div className="d-flex">
                                        {/* <img src={uit} style={{ width: '35px', height: '35px' }} /> */}
                                        <div>
                                            <h5 className="fw-bold">University of Information Technology</h5>
                                            <p className="mb-2 fw-bold">Bachelor of Science in Computer Science – High Performance Computing</p>
                                            <p>Dec 2014 - Oct 2019</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </MDBContainer>
                    </Col>
                    <Col col={12} md={6}>
                        <h5 className="pt-3">Experience</h5>
                        <MDBContainer className="pt-2">
                            <ul className="timeline">
                                <li className="timeline-item mb-5">
                                    <h5 className="fw-bold">Software Engineer | Innoveller</h5>
                                    <p className="mb-2 fw-bold">Myanmar’s top travel startup: Bus ticketing and cargo management solutions.</p>
                                    <p>Aug 2020 - Jan 2023</p>
                                </li>

                                <li className="timeline-item mb-5">
                                    <h5 className="fw-bold">Junior Java Developer | FPT Software Myanmar</h5>
                                    <p className="mb-2 fw-bold">Digital consulting services, digital technology services, and outsourcing services.</p>
                                    <p>Nov 2019 - Feb 2020</p>
                                </li>

                                <li className="timeline-item mb-5">
                                    <h5 className="fw-bold">Software Intern | Axon Active Vietnam</h5>
                                    <p className="mb-2 fw-bold">Agile Offshore Software Development Company.</p>
                                    <p>Jun 2019 - Sep 2019</p>
                                </li>
                            </ul>
                        </MDBContainer>
                    </Col>
                </Row>
            </Container>

        </section>

    );
}