import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiMedium } from "react-icons/si";
import { FaCloudDownloadAlt } from "react-icons/fa";
import profile2 from '../assets/img/profile-pic(8).png';



export const About = () => {
    return (
        <section className="about py-3 mb-5" id="about">
            <h2>About Me 🪴</h2>
            <Container className="pt-3">
                <Row className="d-flex align-items-center justify-content-center">
                    <Col xs={12} md={8} className="order-last order-md-first">
                        <div className="content">


                            Agile Software Engineer with 3 years of experience and having worked in fast-paced startup environments.
                            I specialize in Java (Play Framework, Spring Boot), JavaScript, React, REST API design and integration, Unit testing, and Database design and development.
                            Holding the AWS Developer – Associate certification, I bring expertise in cloud computing and AWS services.

                            <br /><br />

                            I have worked with the leading travel start-up company which is the one I have always been proud of.
                            There I have contributed to ticket booking, e-commerce, internal payment modules, delivery and pricing management, and hotel property management systems.
                            I enjoyed the challenge of exploring new ideas, testing different approaches, and quickly adapting to changing requirements.

                            <br /><br />

                            I am committed to continuous improvement and learning. If you're looking for a motivated and results-oriented software engineer, I'd love to connect with you.
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-4">
                            <a href="https://drive.google.com/file/d/1mMvgcMHV4R_CMW1ywFQcCigI8kKiVnHw/view?usp=drive_linkhttps://drive.google.com/file/d/1mMvgcMHV4R_CMW1ywFQcCigI8kKiVnHw/view" download="Sindhu_Kumari_Resume.pdf" type="application/pdf"
                                className="btn btn-light d-flex justify-content-center align-items-center me-3" target="_blank">
                                Download Resume
                                <FaCloudDownloadAlt className="ms-2" />
                            </a>

                            <a href="https://www.linkedin.com/in/sindhu-kumarizyd/" target="_blank" style={{ fontSize: '30px', color: '#fff' }}>
                                <FaLinkedin />
                            </a>
                            <a href="https://github.com/Kumaridu" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                                <FaGithub />
                            </a>
                            <a href="https://twitter.com/kumarizyd" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                                <FaSquareXTwitter />
                            </a>
                            <a href="https://medium.com/@oceansindhu200" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                                <SiMedium />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="order-first order-md-last mb-3">
                        <div className="d-flex align-items-center justify-content-center">
                            <img
                                src={profile2} // Replace with your photo URL
                                alt="Photo"
                                style={{ borderRadius: '50%', width: '320px', height: '320px', objectFit: 'contain', border: '1px solid #fff' }}
                            />
                        </div>

                    </Col>
                </Row>

            </Container>

        </section>
    )
}