
import profile2 from '../assets/img/profile-pic(8).png';
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiMedium } from "react-icons/si";

export const ContactInfo = () => {
    return (
        <div className="card contact-info">
            <div className="card-img-top d-none d-md-block" style={{ marginBottom: '110px' }}>
                <img src={profile2} />
            </div>
            <div className="card-body text-center">
                {/* <h5 className="card-title">Let's make the world a better place to live</h5> */}
                <div className="card-text">
                    <div><span className="text-secondary">Phone:</span> +1(641)233-9463</div>
                    <div><span className="text-secondary">Email:</span>  <a href="mailto:sindhu.kumarizyd@gmail.com" style={{ textDecoration: 'none', color: '#fff' }}>sindhu.kumarizyd@gmail.com</a></div>
                </div>
                <div className="d-flex align-items-center justify-content-center">

                    <a href="https://www.linkedin.com/in/sindhu-kumarizyd/" target="_blank" style={{ fontSize: '30px', color: '#fff' }}>
                        <FaLinkedin />
                    </a>
                    <a href="https://github.com/Kumaridu" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                        <FaGithub />
                    </a>
                    <a href="https://twitter.com/kumarizyd" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                        <FaSquareXTwitter />
                    </a>
                    <a href="https://medium.com/@oceansindhu200" target="_blank" className="ps-3" style={{ fontSize: '30px', color: '#fff' }}>
                        <SiMedium />
                    </a>



                </div>
            </div>

            <div>

            </div>
        </div>
    )
}