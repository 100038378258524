import { Container } from "react-bootstrap"
import kotlin from '../assets/img/kotlinlang-icon.svg';
import java from '../assets/img/java-icon.svg';
import postgreSQL from '../assets/img/postgres.png';
import javascript from '../assets/img/javascript-icon.svg';
import nodeJs from '../assets/img/nodejs-icon.svg';
import reactJs from '../assets/img/reactjs-icon.svg';
import bootstrap from '../assets/img/getbootstrap-icon.svg';
import mongoDb from '../assets/img/mongodb-icon.svg';
import spring from '../assets/img/springio-icon.svg';
import css from '../assets/img/css.png';
import html from '../assets/img/w3_html5-icon.svg';
import git from '../assets/img/git-scm-icon.svg';
import gitHub from '../assets/img/github-icon.svg';
import docker from '../assets/img/docker-icon.svg';
import restapi from '../assets/img/restapi.jpg';
import aws from '../assets/img/amazon_aws-icon.svg';
import developer from '../assets/img/amazon_developer_associate.png';
import { FaJenkins } from "react-icons/fa";
import { BsBoxArrowUpRight } from "react-icons/bs";


export const Skill = () => {
    return (
        <section className="skill py-3 mb-5" id="skills">
            <h2>Skills</h2>
            <Container className="pt-3">
                <div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={java} /> Java
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={kotlin} /> Kotlin
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={javascript} /> JavaScript
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={reactJs} /> React JS
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={nodeJs} /> Node JS
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={spring} /> Spring Boot
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={bootstrap} /> Bootstrap
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={mongoDb} /> MongoDB
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={html} /> HTML
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={css} /> CSS
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={postgreSQL} /> PostgreSQL
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={git} /> Git
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={gitHub} /> GitHub
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <FaJenkins /> Jenkins
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={docker} /> Docker
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={restapi} /> REST
                    </div>
                    <div className="btn text-white btn-floating m-1 me-3 skill-item">
                        <img src={aws} /> Amazon Web Services
                    </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                    <div className="d-flex">
                        <img src={developer} style={{ width: '35px', height: '35px' }} />
                        <div className="ms-2">
                            <p>AWS Certified Developer - Associate</p>
                            <a className="btn btn-light d-flex justify-content-center align-items-center" href="https://www.credly.com/badges/a3a113df-0c76-4232-bb43-332095453af2" target="_blank">
                                View Credentials
                                <BsBoxArrowUpRight className="ms-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </Container>

        </section >

    )
}